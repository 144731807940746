/* styles.css */

@media (max-width: 600px) {
  .hide-on-small-screen {
    display: none;
  }
  .logo-image {
    display: none;
  }
}

@media (max-width: 1000px) {
  .hide-on-medium-screen {
    display: none;
  }
}

.logo-image {
  max-width: 50px;
  height: auto;
}



.splash-screen {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  transition: opacity 1s ease;
  opacity: 1;
  z-index: 1000;
}

.splash-screen.fade-out {
  opacity: 0;
}

.loading-circle {
  border: 5px solid rgba(0, 0, 0, 0.1); /* Light grey border */
  border-top: 5px solid #8910da; /* Blue border for the top side */
  border-radius: 50%; /* Circular shape */
  width: 100px; /* Size of the circle */
  height: 100px; /* Size of the circle */
  animation: spin 2s linear infinite; /* Animation applied here */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}